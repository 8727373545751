<template>
  <div class="repair-record-contaner">
      <!-- 设备信息s -->
      <div class="msg-box mt24 b">
        <p class="line1">
          <span class="id f20">{{ deviceDetail.deviceNumber }}</span>
          <span class="num f16"
            >No.{{
              deviceDetail.systemNumber ? deviceDetail.systemNumber : "-"
            }}</span
          >
        </p>
        <p class="line2">
          <span class="f16 c3"
            >设备类型：{{
              deviceDetail.deviceTypeParentName
                ? deviceDetail.deviceTypeParentName +
                  "-" +
                  deviceDetail.deviceTypeName
                : "-"
            }}</span
          >
          <span class="f16 c3"
            >设备品牌：{{
              deviceDetail.brandName ? deviceDetail.brandName : "-"
            }}</span
          >
          <span class="f16 c3"
            >出厂日期：{{
              deviceDetail.productionDate ? deviceDetail.productionDate : "-"
            }}</span
          >
          <span class="f16 c3"
            >设备型号：{{
              deviceDetail.deviceModel ? deviceDetail.deviceModel : "-"
            }}</span
          >
          <span class="f16 c3"
            >控制系统：{{
              deviceDetail.controlName ? deviceDetail.controlName : "-"
            }}</span
          >
        </p>
      </div>
      <!-- 设备信息e -->
      <!-- 添加设备资料 s -->
      <div class="mt24 add">
        <button>
          <img src="@/assets/images/home/add.png" alt="" />
          <span class="cf f14">
            <router-link :to="{ name: 'add-repair-record', query: { id: id } }">
              添加维修记录
            </router-link>
          </span>
        </button>
      </div>
      <!-- 添加设备资料 e -->
      <!-- 表格展示 start -->
      <el-row class="table-box mt20">
        <el-table
          :data="tableData"
          style="width: 100%"
          header-cell-class-name="table-header"
          border
        >
          <el-table-column prop="num" label="序号" min-width="50"></el-table-column>
          <el-table-column
            prop="whorepair"
            label="维保工程师（主导工程师）"
            min-width="100"
            ><template slot-scope="{ row }">{{
              row.whorepair || "-"
            }}</template></el-table-column
          >
          <el-table-column prop="whoassist" label="协助工程师" min-width="120"
            ><template slot-scope="{ row }">{{
              row.whoassist || "-"
            }}</template></el-table-column
          >
          <el-table-column class-name="blue" label="维保内容" min-width="100">
            <template slot-scope="scope">
              <span @click="seeDetails(scope.$index, scope.row)" class="see p">
                <el-button type="text" @click="seeMore(1, scope.row)"
                  >查看详情</el-button
                >
              </span>
            </template>
          </el-table-column>
          <el-table-column class-name="blue" label="故障现象" min-width="100">
            <template slot-scope="scope">
              <span @click="seeDetails(scope.$index, scope.row)" class="see p">
                <el-button type="text" @click="seeMore(2, scope.row)"
                  >查看详情</el-button
                >
              </span>
            </template>
          </el-table-column>
          <el-table-column
            class-name="blue"
            label="分析与建议"
            prop="addvise"
            min-width="100"
          >
            <template slot-scope="scope">
              <span @click="seeDetails(scope.$index, scope.row)" class="see p">
                <el-button type="text" @click="seeMore(3, scope.row)"
                  >查看详情</el-button
                >
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="time" label="维保时间" min-width="100"
            ><template slot-scope="{ row }">{{
              row.startDate + '-' + row.endDate || "-"
            }}</template></el-table-column
          >
          <!-- <el-table-column prop="file" label="维保文件" width="133"
            ><template slot-scope="{ row }">{{
              row.file || "-"
            }}</template></el-table-column
          > -->
          <el-table-column class-name="blue" label="操作" min-width="100">
            <template slot-scope="scope">
              <a :href="scope.row.fileUrl" target="_blank">维保报告</a>

              <!-- <span
                @click="handleUpdown(scope.$index, scope.row)"
                class="see p"
              >
                维保报告
              </span> -->
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <!-- 表格展示 end -->
      <!-- 分页 -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="listTotal"
        :page-size="pageSize"
        :current-page="page"
        @current-change="pageChange"
        @prev-click="prevChange"
        @next-click="nextChange"
      >
      </el-pagination>
    <!-- <Footer></Footer> -->
    <!-- 查看详情对话框 -->
    <el-dialog
      top="0"
      :visible.sync="recoveryShow"
      width="460px"
      :before-close="handleClose"
      :show-close="false"
      custom-class="recovery-box"
    >
      <!-- 头部 -->
      <el-row
        class="header p32"
        type="flex"
        justify="space-between"
        align="center"
      >
        <div class="left">
          <img src="@/assets/images/recovery/line.png" alt="" />
          <span class="c16 f3">{{ title }}</span>
        </div>
        <div class="right" @click="recoveryShow = false">
          <img src="@/assets/images/recovery/close.png" alt="" />
        </div>
      </el-row>
      <!-- 内容 -->
      <div class="content b">
        <p class="c6 f16">{{ content }}</p>
      </div>
    </el-dialog>
    <!-- 右侧保修 客服 -->
    <!-- <el-row class="fix-right">
      <el-row class="item2">
        <el-button type="text" @click="assderVisible = true">
          <img src="@/assets/images/home/kefu.png" alt="" />
          <p>联系客服</p>
        </el-button>
      </el-row>
    </el-row> -->
    <!-- 弹窗 start -->
    <!-- <el-dialog
      top="0"
      :visible.sync="assderVisible"
      width="600px"
      :show-close="false"
      class="assderTc"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">联系平台</p>
        <img
          src="@/assets/images/home/close.png"
          @click="assderVisible = false"
          class="hand"
        />
      </span>

      <div class="ja-c fs14 font-66 w100">
        <p>
          <img src="@/assets/images/home/phone.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/weixin.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/qq.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/email.png" alt="" />
          <span>13712345678</span>
        </p>
      </div>
    </el-dialog> -->
    <!-- 弹窗结束 -->
    <tell-us bottom="266px"></tell-us>
  </div>
</template>

<script>
import Layout from "@/components/Layout.vue";
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
import TellUs from "@/components/TellUs.vue";
export default {
  name: "YoufujichuangRepairrecord",
  components: {
    Layout,
    Header,
    Nav,
    Footer,
    TellUs,
  },
  data() {
    return {
      assderVisible: false, //客服弹窗
      recoveryShow: false, //详情对话框
      tableData: [], //表格数据
      // 分页相关数据
      page: 1,
      pageSize: 5,
      listTotal: 0,
      id: "", //设备id
      // 查看详情
      title: "维保内容", //详情弹窗标题
      content: "", //详情弹窗内容

      deviceDetail: [], //设备详情
    };
  },

  mounted() {},
  created() {
    // console.log(this.$router);
    this.id = this.$route.query.id;
    // console.log(this.id, "id");
    // 维保记录-分页列表查询
    this.getRepairRecordById();
    // 通过id查询设备信息
    this.getDeviceRes();
  },
  methods: {
    // 通过id查询设备信息
    getDeviceRes() {
      let paramData = {
        id: this.id,
      };
      this.$API
        .getDeviceRes(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.deviceDetail = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查看更多
    seeMore(index, item) {
      //inde 1  维保内容    2故障现象   3分析与建议
      console.log(index, item);
      if (index == 1) {
        this.title = "维保内容";
        this.content = item.maintenanceContent;
      }
      if (index == 2) {
        this.title = "故障现象";
        this.content = item.faultPhenomenon;
      }
      if (index == 3) {
        this.title = "分析与建议";
        this.content = item.analysisSuggest;
      }
      this.recoveryShow = true;
    },
    // 维保记录-分页列表查询
    getRepairRecordById() {
      let paramData = {
        pageNo: this.page,
        pageSize: this.pageSize,
        deviceId: Number(this.id),
      };
      this.$API
        .getRepairRecordById(paramData)
        .then((res) => {
          if (res.code == 1) {
            // num: "1",
            // whorepair: "白小白",
            // whoassist: "李工程师、王工程师",
            // time: "2021/10/10-2021/10/11",
            // file: "文件名称A.pdf",
            // handle: "下载文件",
            this.listTotal = Number(res.data.total);
            this.tableData = [];
            res.data.records.forEach((item, index) => {
              this.tableData.push({
                num: (this.page - 1) * this.pageSize + index + 1,
                whorepair: item.currentEngineerName, //主工程师姓名
                whoassist: item.viceEngineerName, //协助工程师姓名
                time: item.createTime, //创建时间
                file: item.fileName, //文件名
                id: item.id,
                maintenanceContent: item.workContent, //维保内容
                faultPhenomenon: item.faultPhenomenon, //故障现象
                analysisSuggest: item.analysisSuggest, //分析与建议
                fileUrl: item.pdfUrl,
                startDate: item.startDate,
                endDate: item.endDate
              });
            });
            console.log(this.tableData, "tabefdata");
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 当前页码改变
    pageChange(page) {
      this.page = page;
      this.tableData = [];
      this.getRepairRecordById();
    },

    //弹窗方法
    assderHandle() {
      this.assderVisible = false;
    },
    //对话框处理函数
    handleClose() {
      this.recoveryShow = false;
    },
    // 查看详情
    seeDetails() {},
    // 下载文件
    handleUpdown(index, item) {
      if (!item.fileUrl) {
        this.$message({
          message: "暂无资源可下载",
          icon: "none",
        });
        return;
      }
      // let url = item.fileUrl;
      let urlArr = item.fileUrl.split(",");
      // console.log(urlArr, "ppp");
      urlArr.forEach((item) => {
        let houzhuiArr = item.split(".");
        let houzhui = houzhuiArr[houzhuiArr.length - 1];
        if (houzhui == "pdf") {
          // console.log("下载pdf文件");
          this.fileLinkToStreamDownload(item);
        } else {
          window.open(item, "_self");
        }
      });
    },
    // 下载pdf方法
    fileLinkToStreamDownload(url) {
      let fileName = this.getDay();
      let reg =
        /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/;
      if (!reg.test(url)) {
        throw new Error("传入参数不合法,不是标准的文件链接");
      } else {
        let xhr = new XMLHttpRequest();
        xhr.open("get", url, true);
        xhr.setRequestHeader("Content-Type", `application/pdf`);
        xhr.responseType = "blob";
        let that = this;
        xhr.onload = function () {
          if (this.status == 200) {
            //接受二进制文件流
            var blob = this.response;
            that.downloadExportFile(blob, fileName);
          }
        };
        xhr.send();
      }
    },
    downloadExportFile(blob, tagFileName) {
      let downloadElement = document.createElement("a");
      let href = blob;
      if (typeof blob == "string") {
        downloadElement.target = "_blank";
      } else {
        href = window.URL.createObjectURL(blob); //创建下载的链接
      }
      downloadElement.href = href;
      // downloadElement.download =
      //   tagFileName +
      //   //下载后文件名
      //   document.body.appendChild(downloadElement);
      downloadElement.download = tagFileName;
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      if (typeof blob != "string") {
        window.URL.revokeObjectURL(href); //释放掉blob对象
      }
    },
    getDay() {
      let time = new Date(),
        year = time.getFullYear(),
        month = time.getMonth() + 1,
        day = time.getDate(),
        timeStem = time.getTime();
      return `${year}/${month}/${day}/${timeStem}.pdf`;
    },
    // 下载pdf文件方法结束
    // 点击上一页
    prevChange() {},
    // 点击下一页
    nextChange() {},
    // 返回
    toDevice() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
.repair-record-contaner {
  // 分頁
  .el-pagination {
    text-align: right;
    margin-bottom: 46px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #333333;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: red;
    color: #ffffff;
  }

  width: 100%;
  .right {
    button {
      width: 96px;
      height: 40px;
      background: #efefef;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: top;
      }
    }
  }
  //   设备信息
  .msg-box {
    // width: 1120px;
    min-height: 119px;
    background: #ffeff0;
    border-radius: 16px 16px 16px 16px;
    border: 1px solid #ed1b23;
    padding: 24px 20px 20px 20px;
    .line1 {
      padding-bottom: 17px;
      border-bottom: 1px solid #f4c8cb;
      .id {
        color: #ff4656;
        margin-right: 12px;
      }
      .num {
        color: #99989c;
      }
    }
    .line2 {
      margin-top: 16px;
      span {
        margin-right: 43px;
      }
    }
  }
  //   添加设备资料
  .add {
    button {
      width: 149px;
      height: 40px;
      background: #1577fc;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: top;
      }
    }
  }
  // 表格
  .table-box {
    // width: 1003px;
    margin-bottom: 20px;
    .handle {
      ul {
        padding: 0 20px;
        display: flex;
        align-items: center;
        text-align: center;
      }
      li {
        // float: left;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        color: #007bd9;
        line-height: 22px;
        margin-right: 16px;
        &.cancle {
          color: #ff0015;
        }
        &.assess {
          color: #4aa800;
        }
      }
    }
  }
  .recovery-box {
    width: 460px;
    height: 228px;
    .el-dialog__body{
      overflow: hidden;
    }
    .header {
      height: 46px;
      background-color: #e2e2e2;
      padding-top: 13px;
      // margin-bottom: 11px;
      .left {
        img {
          width: 4px;
          height: 14px;
          vertical-align: middle;
          margin-right: 10px;
        }
        span {
          position: relative;
          top: 2px;
        }
      }
      .right {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    // 内容
    .content {
      padding: 20px;
      height: 160px;
      &::-webkit-scrollbar{
        width: 10px;
      }
      &::-webkit-scrollbar-thumb{
        background-color: #C0C0C0;
      }
      overflow-y: scroll;
      p {
        @include dot(6);
        overflow: visible;
      }
    }
    //对话框垂直居中
    top: 50% !important;
    // transform: translateY(-50%);
    // 对话框头部
    .el-dialog__header {
      padding: 0px !important;
    }
    // 对话框内容
    .el-dialog__body {
      padding: 0px !important;
    }
  }
  // 右侧保修 客服
  // .fix-right {
  //   cursor: pointer;
  //   width: 88px;
  //   height: 90px;
  //   border: 1px solid #ebebeb;
  //   position: fixed;
  //   z-index: 1000;
  //   right: 0;
  //   bottom: 251px;
  //   // top: 50%;
  //   // transform: translateY(-50%);
  //   .item1 {
  //     background-color: #ffffff;
  //   }
  //   .item2 {
  //     background-color: #ed1b23;
  //   }
  //   .item1,
  //   .item2 {
  //     height: 90px;
  //     text-align: center;
  //     box-sizing: border-box;
  //     padding-top: 9px;
  //     img {
  //       width: 24px;
  //       height: 24px;
  //     }
  //     p {
  //       color: #757575;
  //       font-size: 14px;
  //       margin-top: 12px;
  //     }
  //   }
  //   .item2 p {
  //     color: #ffffff;
  //   }
  // }
  // 自定义弹窗样式
  // .assderTc {
  //   display: flex;
  //   align-items: center;
  //   .title {
  //     padding-top: 32px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     position: relative;
  //     font-size: 22px;
  //     font-weight: bold;
  //     color: #333333;
  //     img {
  //       width: 32px;
  //       height: 32px;
  //       position: absolute;
  //       top: 32px;
  //       right: 32px;
  //     }
  //   }
  //   ::v-deep .el-dialog__header {
  //     padding: 0;
  //   }
  //   ::v-deep .el-dialog {
  //     border-radius: 0 !important;
  //   }
  //   ::v-deep .el-dialog__body {
  //     padding: 0;
  //   }
  //   ::v-deep .el-dialog__footer {
  //     padding: 0;
  //   }
  //   ::v-deep .el-dialog {
  //     border-radius: 10px;
  //   }
  //   .ja-c {
  //     height: 311px;
  //     box-sizing: border-box;
  //     padding-left: 214px;
  //     padding-top: 22px;
  //     p {
  //       display: flex;
  //       align-items: center;
  //       margin-bottom: 32px;
  //       img {
  //         width: 30px;
  //         height: 30px;
  //       }
  //       span {
  //         font-size: 14px;
  //         color: #333333;
  //         margin-left: 16px;
  //       }
  //     }
  //   }

  //   .btnad {
  //     width: 112px;
  //     height: 40px;
  //     background: rgba(246, 247, 249, 1);
  //     border: 1px solid #ecedef;
  //     opacity: 1;
  //     border-radius: 20px;
  //   }
  //   .btnsad {
  //     color: #333;
  //     width: 112px;
  //     height: 40px;
  //     background: rgba(255, 195, 44, 1);
  //     opacity: 1;
  //     border-radius: 20px;
  //   }
  // }
}
</style>